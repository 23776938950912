
import React, { useState } from 'react';
import { Button, Image, StyleSheet, Text, View } from 'react-native';
import Header from '../shared/header'
import Footer from '../shared/footer'
import { styles } from '../shared/styles'

const fleetCardNote = ({ history }) => {

    const noteText = sessionStorage.getItem("fuelCardPopup");

    return (
        <View style={styles.container}>
            <Header showAmount='true' showHelp='true' showBack='true' history={history} />
            <Text style={styles.Text3}>
                {noteText}
            </Text>
            <Text> </Text>
            <Button id='btnRelayCode' title="Pay With Fuel Card" onPress={() => history.push("/pages/fuelCardPicker")}></Button>
            <Footer />
        </View>
    );
}

export default fleetCardNote