export const requestEmailReceipt = async (url) => {

    console.log('2. submitting email request: ');

    try {
        const fetchResponse = await fetch(url, {
            method: 'get'
        });
        const response = await fetchResponse;
        if (!response.ok) {
            const responseText = response.text();
            console.log(responseText);
            return (responseText);
        }
        return "success";
    } catch (e) {
        console.log('error: ' + e)
        const error_return = { error: e, error_description: e }
        return error_return;
    }
}
