import React from 'react'
import { useState } from 'react';
import { ActivityIndicator, TextInput, Button, Image, StyleSheet, Text, View } from 'react-native';
import { MaterialIcons, AntDesign } from '@expo/vector-icons';
import Header from '../shared/header'
import Footer from '../shared/footer'
import { styles } from '../shared/styles';
import SocketTest from '../shared/socketTest';
import { getTransactionId } from '../shared/utilities/urlUtils';
import { getApiUrl } from '../shared/apis';
import ModalActivityIndicator from 'react-native-modal-activityindicator';
import { requestEmailReceipt } from '../shared/utilities/requestEmailReceipt';
import { requestTextReceipt } from '../shared/utilities/requestTextReceipt';

const successPage = ({ history }) => {

    console.log(typeof (sessionStorage.getItem("load").amount))

    const total = (JSON.parse(sessionStorage.getItem("load")).amount / 100).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
    });


    function emailIsValid(email) {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    }

    function phoneIsValid(phone) {
        return phone.match(/\d/g).length === 10;
    }

    function isNumeric(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    const handleEmailRequest = async () => {
        console.log(inputs);
        if (inputs.email) {
            if (emailIsValid(inputs.email)) {


                const jObj = {
                    txnId: getTransactionId(window.location.href),
                    email: inputs.email
                }
                const emailRequestUrl = getApiUrl("FISCAL_EMAIL_RECEIPT");

                //spinner start
                setWaiting(true);
                const urlQuery = "?txn_id=" + jObj.txnId + "&email=" + jObj.email;
                const err = await requestEmailReceipt(emailRequestUrl + urlQuery);
                if (err.error || err != "success") {
                    //spinner stop
                    setWaiting(false);
                    const errorText = (err.error) ? err.error_description : err;
                    alert(`Error: ${errorText}`);
                }
                else {
                    //spinner stop
                    setWaiting(false);
                    alert('Receipt has been sent to the specified email address.')
                }
            }
            else {
                alert('Please enter a valid email address...')
            }
        } else {
            alert('Please enter an email address...')
        }
    }





    const handleTextRequest = async () => {
        console.log(inputs);
        if (inputs.textNumber) {
            if (isNumeric(inputs.textNumber) && phoneIsValid(inputs.textNumber)) {


                const jObj = {
                    txnId: getTransactionId(window.location.href),
                    textNumber: inputs.textNumber
                }
                const textRequestUrl = getApiUrl("FISCAL_TEXT_RECEIPT");

                //spinner start
                setWaiting(true);
                const urlQuery = "?txn_id=" + jObj.txnId + "&textNumber=" + jObj.textNumber;
                const err = await requestTextReceipt(textRequestUrl + urlQuery);
                if (err.error || err != "success") {
                    //spinner stop
                    setWaiting(false);
                    //const errorText = err.error.error_description; //(err.error) ? err.error_description : err;
                    alert(`Error: Text message could not be sent to the specified number.`);
                }
                else {
                    //spinner stop
                    setWaiting(false);
                    alert('Receipt has been sent to the specified phone number.')
                }
            }
            else {
                alert('Please enter a valid 10-digit phone number...')
            }
        } else {
            alert('Please enter a valid phone number...')
        }
    }

    //handle input changes
    const handleInputChange = (componentName, textValue) => {
        setInputs({ ...inputs, [componentName]: textValue })
        console.log(inputs);
    }

    const [waiting, setWaiting] = useState(false);

    const [inputs, setInputs] = useState({
        transactionId: getTransactionId(window.location.href),
        email: '',
        textNumber: JSON.parse(sessionStorage.getItem("load")).driverPhone
    })

    return (
        <View style={styles.container}>
            <Header />
            <ModalActivityIndicator visible={waiting} size='large' color='white' />
            <ActivityIndicator animating={waiting} title={"Processing..."} />
            <center>
                <AntDesign
                    name="checkcircle"
                    size={48}
                    color="#7fcc56"
                //onPress={() => history.goBack()}
                />
                <h1 id='lblConfirmation'>Payment Received</h1>
                <Text><b>{total}</b> payment has been processed.</Text>
                <br />
                <Text><h2>Please wait in your truck until we notify you that the bills are ready.</h2></Text>
                <br />
            </center>
            <form onSubmit={handleEmailRequest} >
                <div>
                    <div>
                        <Text>Email Address:</Text>
                    </div><div>
                        <TextInput
                            style={styles.TextInput}
                            id='email'
                            name='email'
                            value={inputs.email}
                            onChangeText={text => handleInputChange("email", text)}
                        />
                    </div>
                </div>

                <Text> </Text>



                <div>
                    <Button id='btnEmail' type="submit" onPress={() => { handleEmailRequest() }} title="Get Receipt as Email"></Button>
                </div>
            </form>
            <Text> </Text>
            <form onSubmit={handleTextRequest} >
                <div>
                    <div>
                        <Text>Cell Number:</Text>
                    </div><div>
                        <TextInput
                            style={styles.TextInput}
                            id='textNumber'
                            name='textNumber'
                            value={inputs.textNumber}
                            onChangeText={text => handleInputChange("textNumber", text)}
                        />
                    </div>
                </div>

                <Text> </Text>



                <div>
                    <Button id='btnText' type="submit" onPress={() => { handleTextRequest() }} title="Get Receipt as Text Message"></Button>
                </div>
            </form>
            <Footer />
        </View>
    );

}

export default successPage;