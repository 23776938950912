import React from 'react'

const errorPage = ({ history }) => {

    return (
        <>
            <h1>Error</h1>
            <h2>Invalid Load ID</h2>
        </>
    );

}

export default errorPage;