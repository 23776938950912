import React, { useState, useEffect } from 'react';
import { Button, Image, StyleSheet, Text, View, TextInput } from 'react-native';
import { List } from 'react-native-paper';
import Header from '../shared/header'
import Footer from '../shared/footer'
import { styles } from '../shared/styles'
import { getTransactionId } from '../shared/utilities/urlUtils';
import { getApiUrl } from '../shared/apis';
import { transformPOs } from '../shared/utilities/general';
import Select from 'react-select';
//import {EditableSelect} from 'react-editable-select';
import CreatableSelect from "react-select/creatable";
import LineItems from '../shared/paymentBreakout';


const index2 = ({ history }) => {

  //  const [options,setOptions] = useState([{value: 'Loading...',label: 'Loading...'},{value: 'Loading...',label: 'Loading...'}])
  const [options, setOptions] = useState([{}])

  var loadingOptions = [
    //{value: 'Loading...',label: 'Loading...'},
  ]

  const [expanded, setExpanded] = React.useState(true);
  const handlePress = () => setExpanded(!expanded);

  const [load, setLoad] = useState({});
  const [lineItems, setLineItems] = useState([]);
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const [currentValue, setCurrentValue] = useState("");
  const [carrierStatus, setCarrierStatus] = useState("Start typing...")

  const urlWithParams = getApiUrl("APEX_DATA") + "?txn_id=" + getTransactionId(window.location.href);

  const getLoadFromSession = () => {
    const load = JSON.parse(sessionStorage.getItem("load"));
    if (load != null) {
      setSelectedOption({ value: load.carrier, label: load.carrier });
      setLoad(load);
      if (load.lineItems != null) {
        const paymentDetails = new LineItems();
        paymentDetails.populateFromJSON(load.lineItems);
        paymentDetails.ensureSortedSessionObject("paymentDetails");
        paymentDetails.sortedLineItems = paymentDetails.getSortedLineItems();
        setLineItems(paymentDetails.sortedLineItems);
      }
    }
  }

  const getLoad = async () => {
    const response = await fetch(urlWithParams);

    if (response.status >= 200 && response.status <= 299) {
      const load = await response.json();
      setSelectedOption({ value: load.carrier, label: load.carrier });
      setLoad(load);
      const paymentDetails = new LineItems();
      paymentDetails.populateFromJSON(load.lineItems);
      paymentDetails.ensureSortedSessionObject("paymentDetails");
      paymentDetails.sortedLineItems = paymentDetails.getSortedLineItems();
      setLineItems(paymentDetails.sortedLineItems);
    } else {
      history.push('/pages/error')
    }
  }

  useEffect(() => {
    getLoadFromSession();
  }, []);

  const onChange_handleChange = (newValue: any, actionMeta: any) => {
    //setSelectedOption(newValue)
    setSelectedOption(newValue)

    load.carrier = newValue

    //API call time
    //API_Call_Post_Selected_Carrier(newValue)

  };

  const API_Call_Post_Selected_Carrier = async e => {

    const settings = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };
    var urlWithParamsCARRIER = getApiUrl("CARRIER_LIST") + "?txn_id=" + getTransactionId(window.location.href) + "&carrier=" + encodeURIComponent(e.value)
    const response = await fetch(urlWithParamsCARRIER, settings);

    if (response.status >= 200 && response.status <= 299) {

      //Update session if new carrier:
      const load = JSON.parse(sessionStorage.getItem("load"));
      load.carrier = e.value;
      sessionStorage.setItem("load", JSON.stringify(load));
      //              const data = await response.json();
      //              return data;
      history.push("/pages/index3");
    } else {
      history.push('/pages/error')
    }

  }

  const onInputChange_HandleChange = (inputValue, actionMeta) => {
    API_Call_Get_Carrier_List(inputValue)
  }

  const API_Call_Get_Carrier_List = async e => {
    setCarrierStatus("Loading...")

    if (e.length > 0) {
      setOptions(loadingOptions)
      var urlWithParamsCARRIER = getApiUrl("CARRIER_LIST") + "?subdept=" + load.subDept + "&query=" + e

      const response = await fetch(urlWithParamsCARRIER, {
        method: 'GET'
      });

      if (response.status >= 200 && response.status <= 299) {
        const data = await response.json();

        //            var tmp = options.concat(data.result)
        setCarrierStatus("Carrier not in list...")
        const tmp = data.result.map(function (row) {
          return { value: row.name, label: row.name }
        })

        setOptions(tmp)

      } else {
        history.push('/pages/error')
      }

    } else {
      setOptions(loadingOptions)
      setCarrierStatus("Start typing...")
    }

  }


  function noCreate() {
    return false
  }

  function verifyAndContinue() {

    var selectedCarrier = JSON.stringify(selectedOption)
    var empty = false
    if (selectedCarrier == 'null' || selectedCarrier == '{}') {
      empty = true
    } else if (selectedOption.value == 'undefined') {
      empty = true
    } else {
      empty = false
    }

    if (empty === false) {
      //alert(selectedCarrier)
      API_Call_Post_Selected_Carrier(selectedOption)    //...and continue to next page
    } else {
      alert("Carrier value required")
    }


  }


  //<strong>{load.carrier ? load.carrier : ' Loading...'}</strong></Text>
  //onChange={} options={}
  //<EditableSelect value={selectedOption.label} options={options} onChange={handleChange}/>
  return (
    <View style={styles.container}>

      <Header showHelp='true' showBack='true' history={history} />
      <br />
      <table>
        <tr>
          <td><Text style={styles.Text3}>Is this your payment?</Text></td>
          <td style={{ background: 'green' }} ><center><Text style={{ color: 'white' }}><strong>${isNaN(load.amount) ? ' Loading...' : (load.amount / 100).toFixed(2)}</strong></Text></center></td>
        </tr>
      </table>


      <Text> </Text>
      <table border='0' width='100%' cellPadding='2'>
        <tbody>
          <tr>

            <td width='50%' align='center'>
              VENDOR: <br /><Text style={styles.bigBlack}><strong>{load.vendor ? load.vendor : "N/A"} </strong></Text>
            </td>

            <td width='100%' align='center'>
              CARRIER: <br />
              <Text style={styles.bigBlack}>
                <CreatableSelect
                  className="basic-single"
                  classNamePrefix="select"
                  name="color"
                  value={selectedOption}
                  options={options}
                  onChange={onChange_handleChange}
                  onInputChange={onInputChange_HandleChange}
                  isClearable
                  isValidNewOption={noCreate}
                  noOptionsMessage={() => carrierStatus}
                />

              </Text>
            </td>
          </tr>
          <tr>
            <td width='50%' align='center'>
              SUB DEPARTMENT: <br /><Text style={styles.bigBlack}><strong>{load.subDept}</strong></Text>
            </td>
            <td width='50%' align='center'>
              DOOR: <br /><Text style={styles.bigBlack}><strong>{load.doorText ? load.doorText : load.door}</strong></Text>
            </td>
          </tr>

          <tr>
            <td colSpan={2}>
              PURCHASE ORDERS:<br />
              <TextInput
                multiline={true}
                numberOfLines={4}
                style={{ borderColor: 'gray', borderWidth: 1, width: '100%' }}
                value={load.po_numbers ? transformPOs(load.po_numbers) : ""} editable={false} />
            </td>
          </tr>

        </tbody>
      </table>

      <List.Section
        title="CHARGES:"
      >
        <List.Accordion
          title={"Total Amount: " + new Intl.NumberFormat('en-US',
            { style: 'currency', currency: 'USD' }
          ).format(load.amount / 100)}
          // left={props => <List.Icon {...props} icon="folder" />}    
          theme={{ colors: { primary: 'green' } }}
        >
          {lineItems.map(item => (
            <List.Item
              //title={item.itemDescription}
              left={() => (<Text>{item.itemDescription}</Text>)}
              right={() => (<Text>{new Intl.NumberFormat('en-US',
                { style: 'currency', currency: 'USD' }
              ).format(typeof item.extendedAmount != "undefined" ? item.extendedAmount : 0)} </Text>)}

            />
          ))}

        </List.Accordion>
      </List.Section>



      <br />
      <Text> </Text>
      <Button id='btnVerify' title="VERIFY AND PAY NOW" onPress={() => verifyAndContinue()}></Button>
      <Text> </Text>
      <Button id='btnNotMyPayment' title="NOT MY PAYMENT" onPress={() => history.push("/pages/index2_1")}></Button>
      <Footer />
    </View >
  )
};


export default index2