import React from 'react';
import { useState } from 'react';
import { ActivityIndicator, Modal, TextInput, Button, Label, Image, StyleSheet, Text, View } from 'react-native';
import Header from '../shared/header';
import Footer from '../shared/footer'
import { styles } from '../shared/styles';
import { postFleetCheck } from '../shared/utilities/postFleetCheck';
import { getTransactionId } from '../shared/utilities/urlUtils';
import { getApiUrl } from '../shared/apis';
import ModalActivityIndicator from 'react-native-modal-activityindicator';


const comChek = ({ history }) => {

  //handle form submission
  const handleSubmit = async () => {
    console.log(inputs);
    if (inputs.expressCode
      // && inputs.trailerNumber
      // && inputs.unitNumber
      // && inputs.driverLicenseNumber
      // && inputs.driverLicenseState
      // && inputs.accountNumber
      // && inputs.driverNumber
    ) {
      //alert('all good')

      var prompts = [];
      for (const [key, value] of Object.entries(inputs)) {
        if (key === "expressCode" || key === "transactionId") {
          continue;
        }
        if (value != "") {
          console.log(`${key}: ${value}`);
          prompts.push({ prompt: key, response: value })
        }
      }

      const jObj = {
        txnId: getTransactionId(window.location.href),
        expressCode: inputs.expressCode,
        checkType: 1,
        PayeeFirstName: inputs.PayeeFirstName,
        PayeeLastName: inputs.PayeeLastName,
        UnitNumber: inputs.UnitNumber,
        TripNumber: inputs.TripNumber,
        DriverNumber: inputs.DriverNumber
        //promptResponse: prompts,
        //PayeeFirstName: inputs.PayeeFirstName,
        //PayeeLastName: inputs.PayeeLastName
      }

      const checkUrl = getApiUrl("FISCAL_POST_CHECK");
      //spinner start
      setWaiting(true);
      const err = await postFleetCheck(checkUrl, jObj);

      if (err.error) {
        //spinner stop
        setWaiting(false);
        alert(err.error_description);
      }
      else if (err.status === 64) {
        setShowTripNumber(true);
        setShowDriverName(true);
        setShowPromptText(true);
        alert("Additional information required. Please complete all fields below.");
        setWaiting(false);
      }
      else {
        //spinner stop
        setWaiting(false);
        history.push('/pages/success')
      }
    } else {
      alert('Please complete all fields...')
    }
  }

  //handle input changes
  const handleChange = (componentName, textValue) => {
    setInputs({ ...inputs, [componentName]: textValue })
    console.log(inputs);
  }

  //setup state
  const [waiting, setWaiting] = useState(false);

  const [showExpressCode, setExpressCode] = useState(true);
  const [showDriverID, setShowDriverID] = useState(true);
  const [showUnitNumber, setShowUnitNumber] = useState(true);
  const [showTripNumber, setShowTripNumber] = useState(false);
  const [showDriverName, setShowDriverName] = useState(false);
  const [showPromptText, setShowPromptText] = useState(false);

  const [inputs, setInputs] = useState({
    transactionId: getTransactionId(window.location.href),
    expressCode: '',
    UnitNumber: '',
    DriverNumber: '',
    TripNumber: '',
    PayeeFirstName: '',
    PayeeLastName: ''
  })

  //jsx
  return (
    <>
      <View style={styles.container}>
        <Header showAmount='true' showHelp='true' showBack='true' history={history} subHead='COMCHEK' />
        <ModalActivityIndicator visible={waiting} size='large' color='white' />
        <ActivityIndicator animating={waiting} title={"Processing..."} />
        <form onSubmit={handleSubmit} >
          <div>
            <div>
              <Text>Express Code:</Text>
            </div><div>
              <TextInput
                style={styles.TextInput}
                id='expressCode'
                name='expressCode'
                value={inputs.expressCode}
                onChangeText={text => handleChange("expressCode", text)}
              />
            </div>
          </div>

          <Text></Text>

          {showPromptText ? <>
            <div>
              Additional information is required. Please complete the below fields, as required.
            </div>
            <Text></Text>
          </> : ""}

          <div>
            <div>
              <Text>Unit #:</Text>
            </div><div>
              <TextInput
                style={styles.TextInput}
                id='UnitNumber'
                name='UnitNumber'
                value={inputs.UnitNumber}
                onChangeText={text => handleChange("UnitNumber", text)}
              />
            </div>
          </div>

          <Text></Text>

          <div>
            <div>
              <Text>Driver ID:</Text>
            </div><div>
              <TextInput
                style={styles.TextInput}
                id='DriverNumber'
                name='DriverNumber'
                value={inputs.DriverNumber}
                onChangeText={text => handleChange("DriverNumber", text)}
              />
            </div>
          </div>

          <Text></Text>


          {showTripNumber ? <>
            <div>
              <div>
                <Text>Trip #:</Text>
              </div><div>
                <TextInput
                  style={styles.TextInput}
                  id='TripNumber'
                  name='TripNumber'
                  value={inputs.TripNumber}
                  onChangeText={text => handleChange("TripNumber", text)}
                />
              </div>
            </div>
            <Text></Text></>
            : ""}


          {showDriverName ? <>
            <table border={0} cellpadding={0} cellspacing={0}><tr><td>
              <div>
                <div>
                  <Text>Driver First Name:</Text>
                </div><div>
                  <TextInput
                    style={styles.TextInput}
                    id='PayeeFirstName'
                    name='PayeeFirstName'
                    value={inputs.PayeeFirstName}
                    onChangeText={text => handleChange("PayeeFirstName", text)}
                  />
                </div>
              </div>

            </td>
              <td><Text> </Text></td>
              <td>

                <div>
                  <div>
                    <Text>Driver Last Name:</Text>
                  </div><div>
                    <TextInput
                      style={styles.TextInput}
                      id='PayeeLastName'
                      name='PayeeLastName'
                      value={inputs.PayeeLastName}
                      onChangeText={text => handleChange("PayeeLastName", text)}
                    />
                  </div>
                </div>
              </td></tr></table>

            <Text></Text>
          </>
            : ""}

          <br />

          <div>
            <Button id='btnSubmit' type="submit" onPress={() => { handleSubmit() }} title="Pay"></Button>
          </div>

          <Text> </Text>

          <div>
            <Button id='btnCancel' title="Cancel" onPress={() => history.goBack()}></Button>
          </div>
        </form>
        <Footer />
      </View>
    </>
  );
};

export default comChek