import React from "react";
import { View } from "react-native";
import { styles } from "../shared/styles";
import Header from "../shared/header";
import Footer from "../shared/footer";

const sessionReplaced = ({ history }) => {
  return (
    <View style={styles.container}>
      <Header />
      <center>
        <h1>Session expired.</h1>
        <a style={{ color: "#00539b" }} href={window.location.href}>
          Refresh
        </a>
      </center>
      <Footer />
    </View>
  );
};

export default sessionReplaced;
