import React, { useState } from 'react';
import { Button, Image, StyleSheet, Text, View } from 'react-native';
import Header from '../shared/header'
import Footer from '../shared/footer'
import { styles } from '../shared/styles'
import vcom from '../assets/comchek_bnr.png';


const index4 = ({ history }) => {

  var tog = JSON.parse(sessionStorage.getItem("load")).paymentMethods

  const skipFuelCardPopup = (sessionStorage.getItem("fuelCardPopup") === null);

  return (
    <View style={styles.container}>

      <Header showAmount='true' showHelp='true' showBack='true' history={history} />
      <Text>Select Payment Method:</Text>
      <Text> </Text>
      {tog.CreditCards ? <Button id='btnCreditCard' title="Credit Card" onPress={() => history.push("/pages/creditCard")}></Button> : ""}
      <Text> </Text>
      {(tog.ComdataFleetCards || tog.WexFleetCards) && skipFuelCardPopup ? <Button id='btnFuelCard' title="Fuel Card" onPress={() => history.push("/pages/fuelCardPicker")}></Button> : ""}
      {(tog.ComdataFleetCards || tog.WexFleetCards) && !skipFuelCardPopup ? <Button id='btnFuelCard' title="Fuel Card" onPress={() => history.push("/pages/fleetCardNote")}></Button> : ""}
      <Text> </Text>
      {tog.ComdataFleetChecks || tog.WexFleetChecks ? <Button id='btnFleetCheck' title="Fleet Check" onPress={() => history.push("/pages/fleetCheckPicker")}></Button> : ""}
      <Text> </Text>
      {tog.Relay ? <Button id='btnRelayCode' title="Relay Code" onPress={() => history.push("/pages/relay")}></Button> : ""}
      {/* {tog.CreditCards && (tog.ComdataFleetCards || tog.ComdataFleetChecks) ? <View styles={StyleSheet.vCom}><View><br /><br /><Image id='imgVcom' style={styles.container} source={vcom} style={{ width: '100%', height: 124, resizeMode: 'contain', resizeMethod: 'resize' }} onClick={() => history.push("/pages/creditCard")}></Image></View></View> : ""} */}
      <Footer />
    </View>
  );
}


export default index4