import Constants from 'expo-constants';
export const getJpmNotification = async (subDept, txnId, creationTime) => {

    const url = Constants.manifest.extra.APIBaseUrl + 
    '/payments/getAndSaveJPMInfo' + 
    '?subDept=' + subDept + 
    '&txnId=' + txnId +
    '&createdAt=' + creationTime
    console.log("URL For getting info:",url)
    try {
        const fetchResponse = await fetch(url, {
            method: 'post',
			headers: {Accept: 'application/json','Content-Type': 'application/json'}
        });
        const data = await fetchResponse.json();
        if (data.error) {
            console.log(data.error_description);
        }
		console.log(data);
        return data;
    } catch (e) {
        console.log('error: ' + e)
        const error_return = { error: e, error_description: e }
        return error_return;
    }
}