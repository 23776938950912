import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'
import { getJpmNotification } from '../../shared/utilities/getJpmNotification';
import { getTransactionId } from '../../shared/utilities/urlUtils';

const JpmUI = (props) => {
    const [shouldGetInfo, setShouldGetInfo] = useState(false);
    const [errorDetails, setErrorDetails] = useState("");
    const [load, setLoad] = useState(null);

    useEffect(() => {

        if (shouldGetInfo) {
            getJpmNotification(load.subDept, getTransactionId(window.location.href), load.creationTime).then(res => {
                setErrorDetails(res)
            })
                .catch(err => console.log(err))
                .finally(() => {
                    setShouldGetInfo(false);
                })
        }
    }, [shouldGetInfo])
    useEffect(() => {
        let load = JSON.parse(sessionStorage.getItem("load"));
        setLoad(load);
        const myDropInUI = new DropInUI({
            checkoutSessionToken: props.sessionToken,
            themeValueOverrides: {
                color: {
                    brand: "hsla(208, 100%, 43%, 1)",
                    interactive: "hsla(204, 100%, 48%, 1)",
                },
            },
        });
        myDropInUI.mount("jpmUI")
        const unsubscribe = myDropInUI.subscribe((announcement) => {
            console.info("ANNOUNCEMENT:", announcement);
            setErrorDetails("");
            if (announcement.namespace === "payment" && announcement.level === "info") {
                if (announcement.message === "PaymentSuccess") {
                    getJpmNotification(load.subDept, getTransactionId(window.location.href), load.creationTime)
                    handlePaymentSuccess(announcement);
                }
                if (announcement.message === "PaymentUnsuccessful") {
                    handlePaymentFailure();
                }
            }
            if (announcement.namespace === "payment" && announcement.level === "error") {
                handlePaymentFailure();
            }
        })

        return () => {
            unsubscribe()
        }

    }, [])
    const handlePaymentSuccess = (announcement) => {
        props.onSuccess();
    }

    const handlePaymentFailure = () => {
        setShouldGetInfo(true);
    }

    return (
        <>
            {errorDetails.length > 0 && <div>{errorDetails}</div>}
            <div id="jpmUI"></div>
        </>
    );
};

JpmUI.propTypes = {
    sessionToken: PropTypes.string.isRequired,
    onSuccess: PropTypes.func.isRequired
}

export default JpmUI