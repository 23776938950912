import useWindowDimensions from '../shared/utilities/useWindowDimensions'

import { View, Text, TouchableOpacity, TextInput, StyleSheet } from 'react-native'

import { Font } from '@react-pdf/renderer'

Font.register({ family: 'Roboto', src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf" });

//H:720
//W:470



const styles = StyleSheet.create({
  container: {
    fontFamily: 'Roboto',

    flex: 1,
    width: '85%',
    maxWidth: '470px',
    //flexWrap: "nowrap",
    alignContent: "space-between",
    backgroundColor: '#fff',
    //alignItems: 'center',
    justifyContent: 'flex-start',
    //marginHorizontal: 16,
    marginVertical: 8,
    paddingHorizontal: 8,

  },
  Text2: {
    fontFamily: 'Roboto',
    flex: 0,
    //flexWrap: "nowrap",
    alignContent: "space-between",
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'flex-start',
    borderWidth: 4,
    borderColor: 'red',
    //marginHorizontal: 16,
    marginVertical: 20,
    paddingHorizontal: 20,
    //maxHeight: 50,

  },
  Text3: {
    fontFamily: 'Roboto',
    flex: 0,
    //flexWrap: "nowrap",
    alignContent: "flex-start",
    backgroundColor: '#fff',
    //alignItems: 'center',
    justifyContent: 'flex-start',
    //marginHorizontal: 16,
    marginVertical: 8,
    paddingHorizontal: 8,
    fontSize: 20,
    fontWeight: 'bold',
  },
  Text4: {
    fontFamily: 'Roboto',
    flex: 0,
    //flexWrap: "nowrap",
    alignContent: "space-between",
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'flex-start',
    borderWidth: 4,
    borderColor: 'green',
    //marginHorizontal: 16,
    marginVertical: 20,
    paddingHorizontal: 20,
    backgroundColor: '#e9f5ec',


    //maxHeight: 50,
  },
  TextInput: {
    fontFamily: 'Roboto',
    height: 40,
    borderColor: 'gray',
    borderWidth: 1,
    width: '100%'
  },
  bigBlack: {
    fontFamily: 'Roboto',
    color: 'black',
    fontWeight: 'normal',
    fontSize: 20,
    width: 5,
  },
  container2: {
    fontFamily: 'Roboto',
    flex: 1,
    justifyContent: 'center',
  },
  horizontal: {
    fontFamily: 'Roboto',
    flexDirection: 'row',
    justifyContent: 'space-around',
    padding: 10,
  },
  a: {
    link: {
      color: 'blue'
    },
    visited: {
      color: 'blue'
    },
    hover: {
      color: 'red'
    },
    active: {
      color: 'blue'
    },
  }

});

export { styles }

