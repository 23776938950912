import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-native';
import { StyleSheet, Text, View, Image } from 'react-native'
import { MaterialIcons, AntDesign } from '@expo/vector-icons';
import logo from '../assets/header.png';
import { getTransactionId } from '../shared/utilities/urlUtils';
import { getApiUrl } from '../shared/apis';
import useWindowDimensions from '../shared/utilities/useWindowDimensions'


const Header = ({ subHead, showAmount, showHelp, showBack, history, initLoad } = props) => {

    const [load, setLoad] = useState({});

    if (getTransactionId(window.location.href) === 0) {
        history.push('/pages/error')
        return (<></>);
    }
 const requestOptions = {
        method: 'GET'
    };
    const urlWithParams = getApiUrl("APEX_DATA") + "?txn_id=" + getTransactionId(window.location.href);

    const getLoad = async () => {
        //console.log(getTransactionId(window.location.href));
        //console.log(JSON.parse(sessionStorage.getItem("load")).truckId);



        if (initLoad) {
            let loadLocal = {};
            const response = await (fetch(urlWithParams, requestOptions));
            if (response.status >= 200 && response.status <= 299) {
                loadLocal = await response.json();
                if (loadLocal.status != 4) {
                    setLoad(loadLocal);
                    sessionStorage.setItem("load", JSON.stringify(loadLocal));
                    document.dispatchEvent(new CustomEvent("transactionLoaded"));

                } else if (loadLocal.status === 4) {
                    if (getTransactionId(window.location.href) == loadLocal.txnID) {
                        alert("This Load ID is cancelled");
                    } else {
                        if (confirm("THis PO has been generated with a new Payment LInk, Please click Ok to proceed")) {
                            window.location.href = window.location.origin + "/?load_id=" + loadLocal.txnID;
                        }
                    }
                    history.push('/pages/error')
                }
            } else {
                history.push('/pages/error')
            }

            if (loadLocal.status === 8 || loadLocal.status === 128) {
                //go to success page
                history.push('/pages/success')
            }

        } else {
            setLoad(JSON.parse(sessionStorage.getItem("load")))
        }

    }

    useEffect(() => {
        getLoad();
    }, []);

    return (
        <View styles={StyleSheet.header}>
            <View>
                <Image id='imgLogo' style={styles.container}
                    source={logo}
                    style={{ width: '100%', height: 100 }}
                ></Image>
            </View>
            <table><tbody><tr>
                <td width='1%' align='left'>
                    {showBack ?
                        <AntDesign
                            id='iconGoBack'
                            name="leftcircleo"
                            size={24}
                            color="#055bab"
                            onPress={() => history.goBack()}
                        />
                        : <></>
                    }
                </td>
                <td width='100%' align='center'>
                    {showAmount ?
                        <View>
                            <center>
                                <h4 id='headerTotal' >
                                    {showAmount ? '$' + (load.amount / 100).toFixed(2) : ''}
                                    {subHead ? ' · ' + subHead : ''}
                                </h4>
                            </center>
                        </View>
                        : <></>
                    }
                </td>
                <td width='1%' align='right'>
                    {showHelp ?
                        <AntDesign
                            id='iconShowHelp'
                            name="questioncircleo"
                            size={24}
                            color="#055bab"
                            onPress={() => history.push("/pages/help")}
                        />
                        : <></>
                    }
                </td></tr></tbody></table>

        </View>

    )
}

const styles = StyleSheet.create({
    header: {
        width: '100%',
        height: '100%',
        flexDirection: 'row',
        allignItems: 'center',
        justifyContent: 'center',
    },
    headerText: {
        fontWeight: 'bold',
        fontSize: 20,
        color: '#333',
        letterSpacing: 1,
    },
    helpRow: {
        //direction: 'rtl',
    },
    vCom: {
        width: '100%',
        height: '100%',
        flexDirection: 'row',
        allignItems: 'top',
        justifyContent: 'center',
    },
})

export default withRouter(Header)