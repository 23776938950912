import React from 'react';
import { Form, useState } from 'react';
import { ActivityIndicator, TextInput, Button, Label, Image, StyleSheet, Text, View } from 'react-native';
import Header from '../shared/header'
import Footer from '../shared/footer'
import { styles } from '../shared/styles'
import { postFleetCheck } from '../shared/utilities/postFleetCheck'
import { getTransactionId } from '../shared/utilities/urlUtils';
import { getApiUrl } from '../shared/apis';
import ModalActivityIndicator from 'react-native-modal-activityindicator';
import { RadioButton } from 'react-native-paper';

const EFS = ({ history }) => {

  //handle form submission
  const handleSubmit = async () => {
    console.log(inputs);

    // if (haveCheck === "No") {
    //   inputs.checkNumber = "91919191"
    // }

    if (inputs.expressCode
      // && inputs.trailerNumber
      // && inputs.unitNumber
      // && inputs.driverLicenseNumber
      // && inputs.driverLicenseState
      // && inputs.accountNumber
      // && inputs.driverNumber
    ) {
      //alert('all good')
      const jObj = {
        txnId: getTransactionId(window.location.href),
        expressCode: inputs.expressCode,
        checkNumber: inputs.checkNumber,
        checkType: 4,
        hasPaperCheck: haveCheck
      }
      const checkUrl = getApiUrl("FISCAL_POST_CHECK");
      //spinner start
      setWaiting(true);

      const err = await postFleetCheck(checkUrl, jObj);
      if (err.error) {
        //spinner stop
        setWaiting(false);
        alert(err.error_description);
      }
      else {
        //spinner stop
        setWaiting(false);
        history.push('/pages/success')
      }
    } else {
      alert('Please complete all fields...')
    }
  }

  //handle input changes
  const handleChange = (componentName, textValue) => {
    setInputs({ ...inputs, [componentName]: textValue })
    console.log(inputs);
  }

  // const handleRadioChange = (event) => {
  //   console.log(event.target.value)
  //   setHaveCheck(event.target.value)
  // }

  //setup state
  const [haveCheck, setHaveCheck] = useState('Yes');
  const [waiting, setWaiting] = useState(false);
  const [inputs, setInputs] = useState({
    transactionId: getTransactionId(window.location.href),
    expressCode: '',
    checkNumber: '',
    // driverLicenseNumber: '',
    // driverLicenseState: '',
    // accountNumber: '',
    // driverNumber: '',
    // trailerNumber: '',
    // unitNumber: ''
  })

  //jsx

  /* Radio button select
    <div onChange={handleRadioChange}>
      <input type="radio" value="NoCheck" name="CheckBool" /> No Check
      <input type="radio" value="Check" name="CheckBool" /> Check
    </div>
  */

  return (
    <>
      <View style={styles.container}>
        <Header showAmount='true' showHelp='true' showBack='true' history={history} subHead='EFS' />
        <ModalActivityIndicator visible={waiting} size='large' color='white' />
        <ActivityIndicator animating={waiting} />
        <form onSubmit={handleSubmit} >
          <div>
            <div>
              <Text>Do you have a paper check?</Text>
            </div><div>
              {/* <Form.Radio label="No" checked={gender === 'No'} value="No" onClick={() => setHaveCheck('No')} />
              <Form.Radio label="Yes" checked={gender === 'Yes'} value="Yes" onClick={() => setHaveCheck('Yes')} /> */}
              <table border={0} cellpadding={0} cellspacing={0}>
                <tr>
                  <td>
                    <RadioButton
                      value="first"
                      status={haveCheck === 'No' ? 'checked' : 'unchecked'}
                      onPress={() => setHaveCheck('No')}
                    />
                  </td>
                  <td>
                    <Text>No</Text>
                  </td>
                </tr>
                <tr>
                  <td>

                    <RadioButton
                      value="second"
                      status={haveCheck === 'Yes' ? 'checked' : 'unchecked'}
                      onPress={() => setHaveCheck('Yes')}
                    />
                  </td>
                  <td>
                    <Text>Yes</Text>
                  </td>
                </tr>
              </table>

            </div>
            <div>
              <Text> </Text>
            </div>
            <div>
              <Text>Money Code:</Text>
            </div><div>
              <TextInput
                style={styles.TextInput}
                id='expressCode'
                name='expressCode'
                value={inputs.expressCode}
                onChangeText={text => handleChange("expressCode", text)}
              />
              {
                haveCheck === 'Yes' ?

                  <div>
                    <div>
                      <div>
                        <Text> </Text>
                      </div>
                      <Text>Check Number:</Text>
                    </div><div>
                      <TextInput
                        style={styles.TextInput}
                        id='checkNumber'
                        name='checkNumber'
                        value={inputs.checkNumber}
                        onChangeText={text => handleChange("checkNumber", text)}
                      />
                    </div>
                  </div>
                  : ''
              }
            </div>

          </div>

          <Text> </Text>


          <div>
            <Button id='btnSubmit' type="submit" onPress={() => { handleSubmit() }} title="Pay"></Button>
          </div>

          <Text> </Text>

          <div>
            <Button id='btnCancel' title="Cancel" onPress={() => history.goBack()}></Button>
          </div>
        </form>
        <Footer />
      </View>
    </>
  );
};

export default EFS


// import React from 'react';
// import { useState } from 'react';
// import { ActivityIndicator, TextInput, Button, Label, Image, StyleSheet, Text, View } from 'react-native';
// import Header from '../shared/header'
// import { styles } from '../shared/styles'
// import { postFleetCheck } from '../shared/utilities/postFleetCheck'
// import { getTransactionId } from '../shared/utilities/urlUtils';
// import { getApiUrl } from '../shared/apis';
// import ModalActivityIndicator from 'react-native-modal-activityindicator';

// const EFS = ({ history }) => {

//   //handle form submission
//   const handleSubmit = async () => {
//     console.log(inputs);
//     if (inputs.expressCode
//       // && inputs.trailerNumber
//       // && inputs.unitNumber
//       // && inputs.driverLicenseNumber
//       // && inputs.driverLicenseState
//       // && inputs.accountNumber
//       // && inputs.driverNumber
//     ) {
//       //alert('all good')
//       const jObj = {
//         txnId: getTransactionId(window.location.href),
//         expressCode: inputs.expressCode,
//         checkNumber: inputs.checkNumber,
//         checkType: 4
//       }
//       const checkUrl = getApiUrl("FISCAL_POST_CHECK");
//       //spinner start
//       setWaiting(true);
//       const err = await postFleetCheck(checkUrl, jObj);
//       if (err.error) {
//         //spinner stop
//         setWaiting(false);
//         alert(err.error_description);
//       }
//       else {
//         //spinner stop
//         setWaiting(false);
//         history.push('/pages/success')
//       }
//     } else {
//       alert('Please complete all fields...')
//     }
//   }

//   //handle input changes
//   const handleChange = (componentName, textValue) => {
//     setInputs({ ...inputs, [componentName]: textValue })
//     console.log(inputs);
//   }

//   const handleRadioChange = (event) => { 
//     console.log(event.target.value)
//   }

//   //setup state
//   const [waiting, setWaiting] = useState(false);
//   const [inputs, setInputs] = useState({
//     transactionId: getTransactionId(window.location.href),
//     expressCode: '',
//     checkNumber: '',
//     // driverLicenseNumber: '',
//     // driverLicenseState: '',
//     // accountNumber: '',
//     // driverNumber: '',
//     // trailerNumber: '',
//     // unitNumber: ''
//   })

//   //jsx

//   /* Radio button select
//     <div onChange={handleRadioChange}>
//       <input type="radio" value="NoCheck" name="CheckBool" /> No Check
//       <input type="radio" value="Check" name="CheckBool" /> Check
//     </div>
//   */

//   return (
//     <>
//       <View style={styles.container}>
//         <Header showAmount='true' showHelp='true' showBack='true' history={history} subHead='EFS' />
//         <ModalActivityIndicator visible={waiting} size='large' color='white' />
//         <ActivityIndicator animating={waiting} />
//         <form onSubmit={handleSubmit} >
//           <div>
//             <div>
//               <Text>Express Code:</Text>
//             </div><div>
//               <TextInput
//                 style={styles.TextInput}
//                 id='expressCode'
//                 name='expressCode'
//                 value={inputs.expressCode}
//                 onChangeText={text => handleChange("expressCode", text)}
//               />

//               <Text>Check Number:</Text>
//             </div><div>
//               <TextInput
//                 style={styles.TextInput}
//                 id='checkNumber'
//                 name='checkNumber'
//                 value={inputs.checkNumber}
//                 onChangeText={text => handleChange("checkNumber", text)}
//               />
//             </div>
//           </div>

//           <Text> </Text>



//           <div>
//             <Button id='btnSubmit' type="submit" onPress={() => { handleSubmit() }} title="Pay"></Button>
//           </div>

//           <Text> </Text>

//           <div>
//             <Button id='btnCancel' title="Cancel" onPress={() => history.goBack()}></Button>
//           </div>
//         </form>

//       </View>
//     </>
//   );
// };

// export default EFS