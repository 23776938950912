import React, { useState, useEffect } from 'react';
import { TextInput, Button, Label, Image, StyleSheet, Text, View } from 'react-native';
import Header from '../shared/header'
import Footer from '../shared/footer'
import { styles } from '../shared/styles'
import ModalActivityIndicator from 'react-native-modal-activityindicator';
import { getApiUrl } from '../shared/apis';
import JpmUI from './Components/jpmUI';
import { postCreditCard } from '../shared/utilities/postCreditCard';
import { getTransactionId } from '../shared/utilities/urlUtils';
import { getJPMDropInUIUrl } from '../shared/utilities/getJPMDropInUIUrl';

const creditCard = ({ history }) => {

  const [waiting, setWaiting] = useState(true);
  const [processorParam, setProcessorParam] = useState();
  const [creditCardProcessor, setCreditCardProcessor] = useState();
  const [jpmHasError, setJpmHasError] = useState(false);

  const loadCreditCardProcessor = (err) => {
    setCreditCardProcessor(err.creditCardProcessor);
    setProcessorParam(err.processorParam);
    setWaiting(false);
    setJpmHasError(false);
  }

  const getIframeUrl = async () => {
    const jObj = {
      txnId: getTransactionId(window.location.href)
    }
    const ccURL = getApiUrl("WORLD_PAY");
    //spinner start
    setWaiting(true);
    const err = await postCreditCard(ccURL, jObj);
    if (err.error) {
      //spinner stop
      setWaiting(false);
      if (err.error_description == "Authorized") {
        history.push('/pages/success')
      }
      else {
        alert(err.error_description);
      }
    }
    else if (err?.jpmError?.hasError) {
      setJpmHasError(true);
      setCreditCardProcessor(err.creditCardProcessor);
      setWaiting(false);
    }
    else {
      const ccURL = getApiUrl("JPM_CHECKOUT_URL");
      if(err.creditCardProcessor === 'jpm')
        {

          const url = await getJPMDropInUIUrl(ccURL);
          const script = document.createElement("script");
          script.src = url;
          script.type = 'module';
    
          document.head.appendChild(script);
    
          script.onload = () =>  loadCreditCardProcessor(err);
          
        }else{
          loadCreditCardProcessor(err)
        }
    }

  }

  useEffect(() => {
    getIframeUrl();
  }, []);
  const worldPayIFrame = <div style={{ height: 700 }}>
    <iframe id='iFrameCC' src={processorParam} frameBorder={0} width='100%' height='100%' />
  </div>;

  const jpmIFrame = () => {
    if (jpmHasError) return <Text style={styles.Text2}>
      <br />
      JPM Error: There was an error creating a checkout session.
      <br /><br />
    </Text>
    return <JpmUI sessionToken={processorParam} onSuccess={onJpmSuccess} />
  }

  const onJpmSuccess = () => {
    history.push('/pages/success')
  }

  return (
    <View style={styles.container}>
      <Header showAmount='true' showHelp='true' showBack='true' history={history} subHead='CREDIT CARD' />
      <ModalActivityIndicator visible={waiting} size='large' color='white' />
      {
        !waiting && (creditCardProcessor !== "jpm" ? worldPayIFrame : jpmIFrame())
      }
      <Footer />
    </View>
  );
}

export default creditCard
