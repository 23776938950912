import React from 'react';
import { useState } from 'react';
import { ActivityIndicator, TextInput, Button, Label, Image, StyleSheet, Text, View } from 'react-native';
import Header from '../shared/header'
import Footer from '../shared/footer'
import { styles } from '../shared/styles'
import { postFleetCheck } from '../shared/utilities/postFleetCheck'
import { getTransactionId } from '../shared/utilities/urlUtils';
import { getApiUrl } from '../shared/apis';
import ModalActivityIndicator from 'react-native-modal-activityindicator';

const TChek = ({ history }) => {

  //handle form submission
  const handleSubmit = async () => {
    console.log(inputs);
    if (inputs.expressCode
      // && inputs.trailerNumber
      // && inputs.unitNumber
      // && inputs.driverLicenseNumber
      // && inputs.driverLicenseState
      // && inputs.accountNumber
      // && inputs.driverNumber
    ) {
      //alert('all good')
      const jObj = {
        txnId: getTransactionId(window.location.href),
        expressCode: inputs.expressCode,
        checkType: 128
      }
      const checkUrl = getApiUrl("FISCAL_POST_CHECK");
      //spinner start
      setWaiting(true);
      const err = await postFleetCheck(checkUrl, jObj);
      if (err.error) {
        //spinner stop
        setWaiting(false);
        alert(err.error_description);
      }
      else {
        //spinner stop
        setWaiting(false);
        history.push('/pages/success')
      }
    } else {
      alert('Please complete all fields...')
    }
  }

  //handle input changes
  const handleChange = (componentName, textValue) => {
    setInputs({ ...inputs, [componentName]: textValue })
    console.log(inputs);
  }

  //setup state
  const [waiting, setWaiting] = useState(false);
  const [inputs, setInputs] = useState({
    transactionId: getTransactionId(window.location.href),
    expressCode: '',
    // driverLicenseNumber: '',
    // driverLicenseState: '',
    // accountNumber: '',
    // driverNumber: '',
    // trailerNumber: '',
    // unitNumber: ''
  })

  //jsx
  return (
    <>
      <View style={styles.container}>
        <Header showAmount='true' showHelp='true' showBack='true' history={history} subHead='TCHEK' />
        <ModalActivityIndicator visible={waiting} size='large' color='white' />
        <ActivityIndicator animating={waiting} />
        <form onSubmit={handleSubmit} >
          <div>
            <div>
              <Text>Money Code:</Text>
            </div><div>
              <TextInput
                style={styles.TextInput}
                id='expressCode'
                name='expressCode'
                value={inputs.expressCode}
                onChangeText={text => handleChange("expressCode", text)}
              />
            </div>
          </div>

          <Text> </Text>



          <div>
            <Button id='btnSubmit' type="submit" onPress={() => { handleSubmit() }} title="Pay"></Button>
          </div>

          <Text> </Text>

          <div>
            <Button id='btnCancel' title="Cancel" onPress={() => history.goBack()}></Button>
          </div>
        </form>
        <Footer />
      </View>
    </>
  );
};

export default TChek