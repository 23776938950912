
import React from 'react';
import { Button, Image, StyleSheet, Text, View } from 'react-native';
import Header from '../shared/header'
import Footer from '../shared/footer'
import { styles } from '../shared/styles'


export default ({ history }) => (
  <View style={styles.container}>
    <Header showAmount='true' showBack='true' history={history} />
    <Text style={styles.Text3}>
      Not your payment?
    </Text>
    <Text> </Text>
    <Text>
      If the payment details are incorrect, please follow these directions:
    </Text>
    <Text> </Text>
    <Text>
      1. Talk to the local Capstone Representative.
    </Text>
    <Text>
      2. Tell them what information is wrong
    </Text>
    <Text> </Text>


    <Text> </Text>
    <Footer />
  </View>
);


