export const postRelayCode = async (url, payload) => {

    console.log('2. Posting request: ' + payload);

    try {
        const fetchResponse = await fetch(url, {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(payload)
        });
        const data = await fetchResponse.json();
        if (data.error) {
            console.log(data.error_description);
        }
        return data;
    } catch (e) {
        console.log('error: ' + e)
        const error_return = { error: e, error_description: e }
        return error_return;
    }
}
