
export const transformPOs = (pos) => {
    if (pos) {
        let retval = "";
        pos.forEach(po => {
            retval += (po + '\n');
        });
        return retval;
    }
    return '';
}

export const transformPOsHtml = (pos) => {
    if (pos) {
        let retval = "";
        pos.forEach(po => {
            retval += (po + "\n");
        });
        return retval;
    }
    return '';
}