import React from 'react';
import { useState } from 'react';

import { ActivityIndicator, Modal, TextInput, Button, Label, Image, StyleSheet, Text, View } from 'react-native';
import Header from '../shared/header';
import Footer from '../shared/footer'
import { styles } from '../shared/styles';
import { postRelayCode } from '../shared/utilities/postRelayCode';
import { getTransactionId } from '../shared/utilities/urlUtils';
import { getApiUrl } from '../shared/apis';
import ModalActivityIndicator from 'react-native-modal-activityindicator';



const relay = ({ history }) => {

    const [relayCode, onChangeText] = useState("");
    const [waiting, setWaiting] = useState(false);

    const handleSubmit = async () => {
        console.log("Post Relay Code", relayCode)
        if (relayCode.trim() != "") {
            const jObj = {
                txnId: getTransactionId(window.location.href),
                expressCode: relayCode
            }
            const relayPostUrl = getApiUrl("RELAY_POST_RELAY_CODE");

            //spinner start
            setWaiting(true);
            const err = await postRelayCode(relayPostUrl, jObj);

            if (err.error) {
                //spinner stop
                setWaiting(false);
                alert(err.error_description);
            } else {
                //spinner stop
                setWaiting(false);
                history.push('/pages/success')
            }
        } else {
            alert('Please complete all fields...')
        }
    }

    const RelayCode_onChangeText = e => {

    }

    return (
        <View style={styles.container}>

            <Header />
            <Text>Enter 6-digit Relay Code that was provided by your dispatcher or broker.</Text>
            <Text> </Text>
            <ModalActivityIndicator visible={waiting} size='large' color='white' />
            <ActivityIndicator animating={waiting} title={"Processing..."} />
            <form onSubmit={handleSubmit}>
                <Text>RELAY CODE:</Text>
                <TextInput maxLength={6} style={{ height: 40, borderColor: 'gray', borderWidth: 1 }} onChangeText={onChangeText} value={relayCode.trim()} />
                <Text> </Text>
                <Button id='btnSubmit' type="submit" onPress={() => { handleSubmit() }} title="Pay" ></Button>
                <Text> </Text>
                <Button id='btnCancel' title="Cancel" onPress={() => history.goBack()}></Button>
            </form>
            <Footer />
        </View>

    );

};


export default relay

/*






*/