
import React from 'react';

class LineItem {
    constructor(displayOrder, itemDescription, extendedAmount) {
        this.displayOrder = displayOrder;
        this.itemDescription = itemDescription;
        this.extendedAmount = extendedAmount;
    }
}

export default class LineItems extends React.Component {

    constructor(props) {
        super(props)
    }

    items = [];

    populateFromJSON(LineItems) {
        LineItems.forEach((lineItem) => {
            this.items.push(new LineItem(lineItem.displayOrder, lineItem.itemDescription, lineItem.extendedAmount))
        });
    }

    getSortedLineItems() {
        let sortedItems = [...this.items];
        sortedItems.sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : -1)
        return (sortedItems);
    }

    ensureSortedSessionObject(ssKey) {
        if (sessionStorage.getItem(ssKey) === null) {
            sessionStorage.setItem(ssKey, JSON.stringify(this.getSortedLineItems()))
        }
    }

}