import React, { useState, useEffect } from 'react';
import { Button, Image, StyleSheet, Text, View, TextInput } from 'react-native';
import Header from '../shared/header'
import Footer from '../shared/footer'
import { styles } from '../shared/styles'
import { getTransactionId } from '../shared/utilities/urlUtils';
import { getApiUrl } from '../shared/apis';
import { transformPOsHtml } from '../shared/utilities/general';

const index3 = ({ history }) => {

  const [load, setLoad] = useState({});

  const urlWithParams = getApiUrl("APEX_DATA") + "?txn_id=" + getTransactionId(window.location.href);

  const getLoad = async () => {
    const response = await fetch(urlWithParams);
    if (response.status >= 200 && response.status <= 299) {
      const load = await response.json();
      setLoad(load);
    } else {
      history.push('/pages/error')
    }
  }

  const getLoadFromSession = () => {
    const load = JSON.parse(sessionStorage.getItem("load"));
    setLoad(load);
  }

  useEffect(() => {
    getLoadFromSession();
  }, []);

  return (
    <View style={styles.container}>

      <Header showHelp='true' showBack='true' history={history} />
      <br />
      <Text style={styles.Text3}>
        Are you sure this is your payment?
      </Text>
      <Text> </Text>
      <Text>
        Payments are final and can not be reversed.
      </Text>
      <Text> </Text>

      <Text style={styles.Text4} >
        <table border='0' width='100%' cellPadding='10' >
          <tbody>
            <tr >
              <td width='50%' align='center' style={{ maxWidth: 5 }}>
                TOTAL: <br /><Text style={styles.bigBlack}>${isNaN(load.amount) ? ' Loading...' : (load.amount / 100).toFixed(2)}</Text>
              </td>
              <td width='50%' align='center' style={{ maxWidth: 5 }}>
                CARRIER: <br /><Text style={styles.bigBlack}> {load.carrier ? load.carrier : ' Loading...'}</Text>
              </td>
            </tr>
            <tr>
              <td width='50%' align='center' style={{ maxWidth: 5 }}>
                SUB DEPARTMENT:<br /><Text style={styles.bigBlack}>{load.subDept}</Text>
              </td>
              <td width='50%' align='center' style={{ maxWidth: 5 }}>
                DOOR: <br /><Text style={styles.bigBlack}>{load.doorText ? load.doorText : load.door}</Text>
              </td>
            </tr>
            <tr>
              <td width='50%' align='center' style={{ maxWidth: 5 }}>
                VENDOR: <br /><Text style={styles.bigBlack}>{load.vendor ? load.vendor : "N/A"}</Text>
              </td>
              <td width='50%' align='center' style={{ maxWidth: 5 }}>
                <br />
                PURCHASE ORDER(S): <br />
                <Text
                  style={styles.bigBlack}>
                  {load.po_numbers ? transformPOsHtml(load.po_numbers) : "N/A"}
                </Text>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>

              </td>
            </tr>
          </tbody>
        </table>


      </Text>

      <Text> </Text>

      <Text> </Text>
      <Button id='btnYes' title="YES, THIS IS MY PAYMENT" onPress={() => history.push("/pages/index4")}></Button>
      <Text> </Text>
      <Button id='btnNo' title="NO, NOT MY PAYMENT" onPress={() => history.push("/pages/index2_1")}></Button>
      <Footer />
    </View>
  )
};

export default index3
