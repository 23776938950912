export const getJPMDropInUIUrl = async (url) => {
    const fetchResponse = await fetch(url, {
        method: 'get'
    });
    const response = await fetchResponse;
    if (!response.ok) {
        throw new Error('Failed to fetch data');
    }
    return response.text();
}
