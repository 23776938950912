import React from 'react';
import { useState, useEffect } from 'react'

import { Button, Image, StyleSheet, Text, View } from 'react-native';
import Header from '../shared/header'
import Footer from '../shared/footer'
import { styles } from '../shared/styles'
import { getApiUrl } from '../shared/apis';
import { getTransactionId } from '../shared/utilities/urlUtils';



export default ({ history }) => {

    var tog = JSON.parse(sessionStorage.getItem("load")).paymentMethods;
     
	const requestOptions = {
        method: 'GET'
    };
    const urlWithParams = getApiUrl("APEX_DATA") + "?txn_id=" + getTransactionId(window.location.href) + "&init_transaction=true";
    const response =  (fetch(urlWithParams,requestOptions));
    sessionStorage.setItem('checkType', '0');

    const handleComdataClick = () => {
        sessionStorage.setItem('checkType', '2')
        history.push("/pages/fuelCard")
    }


    const handleTChekClick = () => {
        sessionStorage.setItem('checkType', '64')
        history.push("/pages/fuelCard")
    }

    return (

        <View style={styles.container}>

            <Header showAmount='true' showHelp='true' showBack='true' history={history} />
            <Text>Select Fuel Card Brand:</Text>
            <hr style={{ width: "100%", height: 1 }} />


            <table>
                <tr>
                    <td>
                        {tog.ComdataFleetCards ?
                            //<Button id='btnComdata' title="Comdata" onPress={() => history.push("/pages/fuelCard")}></Button>
                            <center>
                                <Image
                                    style={{
                                        resizeMode: "contain",
                                        height: 66,
                                        width: 100
                                    }}
                                    source={require('../assets/Comdata.png')}
                                    onClick={() => handleComdataClick()}
                                />
                            </center>
                            : ""
                        }
                    </td>
                    <td>
                        {tog.WexFleetCards ?
                            //<Button id='btnTChek' title="TChek" onPress={() => history.push("/pages/fuelCard")}></Button>
                            <center>
                                <Image
                                    style={{
                                        resizeMode: "contain",
                                        height: 66,
                                        width: 100
                                    }}
                                    source={require('../assets/T-Chek.png')}
                                    onClick={() => handleTChekClick()}
                                />
                            </center>
                            : ""
                        }
                    </td>
                    <td>
                        {tog.WexFleetCards ?
                            //<Button id='btnTChek' title="TChek" onPress={() => history.push("/pages/fuelCard")}></Button>
                            <center>
                                <Image
                                    style={{
                                        resizeMode: "contain",
                                        height: 66,
                                        width: 100
                                    }}
                                    source={require('../assets/EFS-t.png')}
                                    onClick={() => history.push("/pages/fuelCard")}
                                />
                            </center>
                            : ""
                        }
                    </td>
                </tr>
                <tr>
                    <td><center><Text>{tog.ComdataFleetCards ? "COMDATA" : ""}</Text></center></td>
                    <td><center><Text>{tog.WexFleetCards ? "T-CHEK" : ""}</Text></center></td>
                    <td><center><Text>{tog.WexFleetCards ? "EFS & TCH" : ""}</Text></center></td>
                </tr>
            </table>
            {/* <hr style={{ width: "100%", height: 1 }} />

            <Text> </Text>

            <Text> </Text>
            {tog.WexFleetCards ? <Button id='btnOther' title="Other" onPress={() => history.push("/pages/fuelCard")}></Button> : ""}
            <Text> </Text> */}

            {/* <Button id='btnCancel' title="Back" onPress={() => history.goBack()}></Button> */}
            <Footer />
        </View >
    );

}

/*


*/