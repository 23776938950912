import React from 'react';
import { useState } from 'react';
import { ActivityIndicator, Modal, TextInput, Button, Label, Image, StyleSheet, Text, View, Picker } from 'react-native';
import Header from '../shared/header';
import Footer from '../shared/footer'
import { styles } from '../shared/styles';
import { postFuelCard } from '../shared/utilities/postFuelCard';
import { getTransactionId } from '../shared/utilities/urlUtils';
import { getApiUrl } from '../shared/apis';
import ModalActivityIndicator from 'react-native-modal-activityindicator';


const fuelCard = ({ history }) => {

  const verifyValues = () => {
    let valid = true;
    if (showUnitNumber && inputs.UnitNumber == "") { valid = false };
    return (valid);
  }

  //handle form submission
  const handleSubmit = async () => {
    console.log(inputs);

    if (verifyValues() == false) {
      const checkType = parseInt(sessionStorage.getItem('checkType'));
      if (checkType === 2) { //comdata card
        alert("Unit Number is required.");
        return false;
      }
      return true;
    }

    if (showExpirationDate) {
      if (inputs.ExpirationDate.indexOf("-") > -1) {
        alert("Please enter a valid expiration date.");
        return false;
      }
    }

    if (inputs.cardNumber
      // && inputs.trailerNumber
      // && inputs.unitNumber
      // && inputs.driverLicenseNumber
      // && inputs.driverLicenseState
      // && inputs.accountNumber
      // && inputs.driverNumber
    ) {
      //alert('all good')

      var prompts = [];
      for (const [key, value] of Object.entries(inputs)) {
        if (key === "cardNumber" || key === "transactionId" || key === "selectedExpYear" || key === "selectedExpMonth") {
          continue;
        }
        if (value != "") {
          console.log(`${key}: ${value}`);
          prompts.push({ prompt: key, response: value })
        }
      }
      const jObj = {
        txnId: getTransactionId(window.location.href),
        cardNumber: inputs.cardNumber,
        promptResponse: prompts
      }
      const checkType = parseInt(sessionStorage.getItem('checkType'));
      if (checkType != 0) {
        jObj.checkType = checkType;
      }
      const fuelCardUrl = getApiUrl("FISCAL_POST_FUEL_CARD");
      //spinner start
      setWaiting(true);
      const err = await postFuelCard(fuelCardUrl, jObj);
      if (err.error) {
        //spinner stop
        setWaiting(false);
        if (err.error_description.toLowerCase().indexOf("prod") > 0) {
          if (inputs.cardNumber.substring(0, 6) == sessionStorage.getItem("masterCardFuelCardBin")) {
            //mastercard bin prompt
            if (confirm(sessionStorage.getItem("masterCardFuelCardProductError"))) {
              history.push('/pages/creditCard')
            } else {
              //stay
            }
            //alert(sessionStorage.getItem("masterCardFuelCardProductError"))
          } else {
            //non mastercard bin prompt
            alert(sessionStorage.getItem("fuelCardProductError"))
          }
        }
        else { alert(err.error_description); }
      }
      else {
        //spinner stop
        setWaiting(false);
        if (err.status === 8) {
          history.push('/pages/success')
        }
        else if (err.promptsNeeded) {
          // if (err.hostMessage){
          //   alert(err.hostMessage);
          // }
          if (err.promptsNeeded.length > 0) {
            err.promptsNeeded.map(function (promptName) {
              //for each requested prompt, show the section in the form
              console.log(promptName);
              eval("setShow" + promptName + "(true)");
            })
          }
        }
      }
    } else {
      alert('Please complete all fields...')
    }
  }

  //handle input changes
  const handleChange = (componentName, textValue) => {
    setInputs({ ...inputs, [componentName]: textValue })
    console.log(inputs);
  }

  const handleMonthChange = (textValue) => {
    setInputs({ ...inputs, ExpirationDate: '' + textValue + inputs.ExpirationDate.slice(2, 6), selectedExpMonth: textValue })
    console.log(inputs);
  }


  const handleYearChange = (textValue) => {
    setInputs({ ...inputs, ExpirationDate: '' + inputs.ExpirationDate.slice(0, 2) + textValue, selectedExpYear: textValue })
    console.log(inputs);
  }

  //setup state
  const [waiting, setWaiting] = useState(false);

  const [showDriverID, setShowDriverID] = useState(false);
  const [showUnitNumber, setShowUnitNumber] = useState(false);
  const [showTrailerNumber, setShowTrailerNumber] = useState(false);
  const [showOdometer, setShowOdometer] = useState(false);
  const [showHubometer, setShowHubometer] = useState(false);
  const [showTrailerHours, setShowTrailerHours] = useState(false);
  const [showTripNumber, setShowTripNumber] = useState(false);
  const [showDriversLicense, setShowDriversLicense] = useState(false);
  const [showDriversLicenseState, setShowDriversLicenseState] = useState(false);
  const [showPONumber, setShowPONumber] = useState(false);
  const [showMisc, setShowMisc] = useState(false);
  const [showTrailerHub, setShowTrailerHub] = useState(false);
  const [showCompanyName, setShowCompanyName] = useState(false);
  const [showCheckAmount, setShowCheckAmount] = useState(false);
  const [showCheckNumber, setShowCheckNumber] = useState(false);
  const [showControlNumber, setShowControlNumber] = useState(false);
  const [showExpressCode, setShowExpressCode] = useState(false);
  const [showExpirationDate, setShowExpirationDate] = useState(false);
  const [showReeferHours, setShowReeferHours] = useState(false);
  const [showPromptControlNumber, setShowPromptControlNumber] = useState(false);
  const [showBirthday, setShowBirthday] = useState(false);


  // const [selectedExpMonth, setSelectedExpMonth] = useState("-");
  // const [selectedExpYear, setSelectedExpYear] = useState("-");


  const [inputs, setInputs] = useState({
    transactionId: getTransactionId(window.location.href),
    cardNumber: '',
    DriverID: '',
    UnitNumber: '',
    TrailerNumber: '',
    Odometer: '',
    Hubometer: '',
    TrailerHours: '',
    TripNumber: '',
    DriversLicense: '',
    DriversLicenseState: '',
    PONumber: '',
    Misc: '',
    TrailerHub: '',
    CompanyName: '',
    CheckAmount: '',
    CheckNumber: '',
    ControlNumber: '',
    ExpressCode: '',
    ExpirationDate: '------',
    selectedExpMonth: '--',
    selectedExpYear: '----',
    ReeferHours: '',
    Birthday: '',
    PromptControlNumber: ''
  })

  //jsx
  return (
    <>
      <View style={styles.container}>
        <Header showAmount='true' showHelp='true' showBack='true' history={history} subHead='FUEL CARD' />
        <ModalActivityIndicator visible={waiting} size='large' color='white' />
        <ActivityIndicator animating={waiting} title={"Processing..."} />
        <form onSubmit={handleSubmit} >
          <div>
            <div>
              <Text>Fuel Card Number:</Text>
            </div><div>
              <TextInput
                style={styles.TextInput}
                id='cardNumber'
                name='cardNumber'
                value={inputs.cardNumber}
                onChangeText={text => handleChange("cardNumber", text)}
              />
            </div>
          </div>
          <Text> </Text>
          {showDriverID ? <><div id="divDriverID"><div><Text> Driver ID:</Text></div><div><TextInput style={styles.TextInput} id='DriverID' name='DriverID' value={inputs.DriverID} onChangeText={text => handleChange("DriverID", text)} /> </div></div><Text> </Text></> : ""}
          {showUnitNumber ? <><div id="divUnitNumber"><div><Text> Unit Number:</Text></div><div><TextInput style={styles.TextInput} id='UnitNumber' name='UnitNumber' value={inputs.UnitNumber} onChangeText={text => handleChange("UnitNumber", text)} /> </div></div><Text> </Text></> : ""}
          {showTrailerNumber ? <><div id="divTrailerNumber"><div><Text> Trailer Number:</Text></div><div><TextInput style={styles.TextInput} id='TrailerNumber' name='TrailerNumber' value={inputs.TrailerNumber} onChangeText={text => handleChange("TrailerNumber", text)} /> </div></div><Text> </Text></> : ""}
          {showOdometer ? <><div id="divOdometer"><div><Text> Odometer:</Text></div><div><TextInput style={styles.TextInput} id='Odometer' name='Odometer' value={inputs.Odometer} onChangeText={text => handleChange("Odometer", text)} /> </div></div><Text> </Text></> : ""}
          {showHubometer ? <><div id="divHubometer"><div><Text> Hubometer:</Text></div><div><TextInput style={styles.TextInput} id='Hubometer' name='Hubometer' value={inputs.Hubometer} onChangeText={text => handleChange("Hubometer", text)} /> </div></div><Text> </Text></> : ""}
          {showTrailerHours ? <><div id="divTrailerHours"><div><Text> Trailer Hours:</Text></div><div><TextInput style={styles.TextInput} id='TrailerHours' name='TrailerHours' value={inputs.TrailerHours} onChangeText={text => handleChange("TrailerHours", text)} /> </div></div><Text> </Text></> : ""}
          {showTripNumber ? <><div id="divTripNumber"><div><Text> Trip Number:</Text></div><div><TextInput style={styles.TextInput} id='TripNumber' name='TripNumber' value={inputs.TripNumber} onChangeText={text => handleChange("TripNumber", text)} /> </div></div><Text> </Text></> : ""}
          {showDriversLicense ? <><div id="divDriversLicense"><div><Text> Driver's License:</Text></div><div><TextInput style={styles.TextInput} id='DriversLicense' name='DriversLicense' value={inputs.DriversLicense} onChangeText={text => handleChange("DriversLicense", text)} /> </div></div><Text> </Text></> : ""}
          {showDriversLicenseState ? <><div id="divDriversLicenseState"><div><Text> Driver's License State:</Text></div><div><TextInput style={styles.TextInput} id='DriversLicenseState' name='DriversLicenseState' value={inputs.DriversLicenseState} onChangeText={text => handleChange("DriversLicenseState", text)} /> </div></div><Text> </Text></> : ""}
          {showPONumber ? <><div id="divPONumber"><div><Text> P.O. Number:</Text></div><div><TextInput style={styles.TextInput} id='PONumber' name='PONumber' value={inputs.PONumber} onChangeText={text => handleChange("PONumber", text)} /> </div></div><Text> </Text></> : ""}
          {showMisc ? <><div id="divMisc"><div><Text> Miscellaneous:</Text></div><div><TextInput style={styles.TextInput} id='Misc' name='Misc' value={inputs.Misc} onChangeText={text => handleChange("Misc", text)} /> </div></div><Text> </Text></> : ""}
          {showTrailerHub ? <><div id="divTrailerHub"><div><Text> Trailer Hub:</Text></div><div><TextInput style={styles.TextInput} id='TrailerHub' name='TrailerHub' value={inputs.TrailerHub} onChangeText={text => handleChange("TrailerHub", text)} /> </div></div><Text> </Text></> : ""}
          {showReeferHours ? <><div id="divReeferHours"><div><Text> Reefer Hours:</Text></div><div><TextInput style={styles.TextInput} id='ReeferHours' name='ReeferHours' value={inputs.ReeferHours} onChangeText={text => handleChange("ReeferHours", text)} /> </div></div><Text> </Text></> : ""}
          {showCompanyName ? <><div id="divCompanyName"><div><Text> Company Name:</Text></div><div><TextInput style={styles.TextInput} id='CompanyName' name='CompanyName' value={inputs.CompanyName} onChangeText={text => handleChange("CompanyName", text)} /> </div></div><Text> </Text></> : ""}
          {showCheckAmount ? <><div id="divCheckAmount"><div><Text> Check Amount:</Text></div><div><TextInput style={styles.TextInput} id='CheckAmount' name='CheckAmount' value={inputs.CheckAmount} onChangeText={text => handleChange("CheckAmount", text)} /> </div></div><Text> </Text></> : ""}
          {showCheckNumber ? <><div id="divCheckNumber"><div><Text> Check Number:</Text></div><div><TextInput style={styles.TextInput} id='CheckNumber' name='CheckNumber' value={inputs.CheckNumber} onChangeText={text => handleChange("CheckNumber", text)} /> </div></div><Text> </Text></> : ""}
          {showControlNumber ? <><div id="divControlNumber"><div><Text> Control Number:</Text></div><div><TextInput style={styles.TextInput} id='ControlNumber' name='ControlNumber' value={inputs.ControlNumber} onChangeText={text => handleChange("ControlNumber", text)} /> </div></div><Text> </Text></> : ""}
          {showPromptControlNumber ? <><div id="divPromptControlNumber"><div><Text> Control Number:</Text></div><div><TextInput style={styles.TextInput} id='PromptControlNumber' name='PromptControlNumber' value={inputs.PromptControlNumber} onChangeText={text => handleChange("PromptControlNumber", text)} /> </div></div><Text> </Text></> : ""}
          {showExpressCode ? <><div id="divExpressCode"><div><Text> Express Code:</Text></div><div><TextInput style={styles.TextInput} id='ExpressCode' name='ExpressCode' value={inputs.ExpressCode} onChangeText={text => handleChange("ExpressCode", text)} /> </div></div><Text> </Text></> : ""}
          {showBirthday ? <><div id="divBirthday"><div><Text> Birthday: <i>MMDDYY</i></Text></div><div><TextInput style={styles.TextInput} id='Birthday' name='Birthday' value={inputs.Birthday} onChangeText={text => handleChange("Birthday", text)} /> </div></div><Text> </Text></> : ""}

          {// showExpirationDate ? <><div id="divExpirationDate"><div><Text> Expiration Date (MMYYYY):</Text></div><div><TextInput style={styles.TextInput} id='ExpirationDate' name='ExpirationDate' value={inputs.ExpirationDate} onChangeText={text => handleChange("ExpirationDate", text)} /> </div></div><Text> </Text></> : ""
          }
          {
            showExpirationDate ?
              <>
                < div id="divExpirationDate">
                  <div>
                    <Text> Expiration Date:</Text>
                  </div>
                  <div>
                    <table><tr><td>
                      <Text>Month:</Text><br /> <Picker
                        selectedValue={inputs.selectedExpMonth}
                        style={{ height: 50, width: 50 }}
                        //onValueChange={(itemValue, itemIndex) => handleChange("selectedExpMonth", itemValue)}
                        onValueChange={(itemValue, itemIndex) => handleMonthChange(itemValue)}
                      >
                        <Picker.Item label="--" value="--" />
                        <Picker.Item label="01" value="01" />
                        <Picker.Item label="02" value="02" />
                        <Picker.Item label="03" value="03" />
                        <Picker.Item label="04" value="04" />
                        <Picker.Item label="05" value="05" />
                        <Picker.Item label="06" value="06" />
                        <Picker.Item label="07" value="07" />
                        <Picker.Item label="08" value="08" />
                        <Picker.Item label="09" value="09" />
                        <Picker.Item label="10" value="10" />
                        <Picker.Item label="11" value="11" />
                        <Picker.Item label="12" value="12" />

                      </Picker>
                    </td><td>
                        <Text>Year:</Text><br />
                        <Picker
                          selectedValue={inputs.selectedExpYear}
                          style={{ height: 50, width: 100 }}
                          //onValueChange={(itemValue, itemIndex) => handleChange("selectedExpYear", itemValue)}
                          onValueChange={(itemValue, itemIndex) => handleYearChange(itemValue)}
                        >
                          <Picker.Item label="----" value="----" />
                          <Picker.Item label="2021" value="2021" />
                          <Picker.Item label="2022" value="2022" />
                          <Picker.Item label="2023" value="2023" />
                          <Picker.Item label="2024" value="2024" />
                          <Picker.Item label="2025" value="2025" />
                          <Picker.Item label="2026" value="2026" />
                          <Picker.Item label="2027" value="2027" />
                          <Picker.Item label="2028" value="2028" />
                          <Picker.Item label="2029" value="2029" />
                          <Picker.Item label="2030" value="2030" />
                          <Picker.Item label="2031" value="2031" />
                          <Picker.Item label="2032" value="2032" />


                        </Picker>
                      </td></tr></table>
                  </div></div><Text> </Text>
              </>
              :
              ""
          }
          <Text> </Text>



          <div>
            <Button id='btnSubmit' type="submit" onPress={() => { handleSubmit() }} title="Pay"></Button>
          </div>

          <Text> </Text>

          <div>
            <Button id='btnCancel' title="Cancel" onPress={() => history.goBack()}></Button>
          </div>
        </form>
        <Footer />
      </View >
    </>
  );
};

export default fuelCard