import React, { useState, useEffect } from 'react';
import {decode as atob, encode as btoa} from 'base-64'
import { Button, Image, StyleSheet, Text, View } from 'react-native';
import Header from '../shared/header'
import Footer from '../shared/footer'
import useWindowDimensions from '../shared/utilities/useWindowDimensions'
import { styles } from '../shared/styles'
import { getTransactionId } from '../shared/utilities/urlUtils';
import { getApiUrl } from '../shared/apis';
import { RadioButton } from 'react-native-paper';


//import SocketTest from '../shared/socketTest';



const index = ({ history }) => {

	const requestOptions = {
        method: 'GET'
    };
  const urlWithParamsFuelCardPopup = getApiUrl("PROPERTY") + "?key=REACT_APP_FUEL_CARD_POPUP_TEXT";
  if (sessionStorage.getItem("fuelCardPopup") === null) {
	  
    fetch(urlWithParamsFuelCardPopup,requestOptions)
      .then(response => response.text())
      .then((response) => {
        if (!response.includes('Property does not exist')) {
          sessionStorage.setItem("fuelCardPopup", response);
        }
      })
      .catch(err => console.log(err))
  }


  const urlWithParamsProductError = getApiUrl("PROPERTY") + "?key=REACT_APP_FUEL_CARD_ERROR_TEXT";
  if (sessionStorage.getItem("fuelCardProductError") === null) {
    fetch(urlWithParamsProductError,requestOptions)
      .then(response => response.text())
      .then((response) => {
        if (!response.includes('Property does not exist')) {
          sessionStorage.setItem("fuelCardProductError", response);
        }
      })
      .catch(err => console.log(err))
  }

  const urlWithParamsMasterCardProductError = getApiUrl("PROPERTY") + "?key=REACT_APP_MASTER_CARD_FUEL_CARD_ERROR_TEXT";
  if (sessionStorage.getItem("masterCardFuelCardProductError") === null) {
    fetch(urlWithParamsMasterCardProductError,requestOptions)
      .then(response => response.text())
      .then((response) => {
        if (!response.includes('Property does not exist')) {
          sessionStorage.setItem("masterCardFuelCardProductError", response);
        }
      })
      .catch(err => console.log(err))
  }

  const urlWithParamsMasterCardBin = getApiUrl("PROPERTY") + "?key=REACT_APP_MASTER_CARD_FUEL_CARD_BIN";
  if (sessionStorage.getItem("masterCardFuelCardBin") === null) {
    fetch(urlWithParamsMasterCardBin,requestOptions)
      .then(response => response.text())
      .then((response) => {
        if (!response.includes('Property does not exist')) {
          sessionStorage.setItem("masterCardFuelCardBin", response);
        }
      })
      .catch(err => console.log(err))
  }

  const [termsAndConditionsURL, setTerms] = useState("#");
  const _termsAndConditionsURL = getApiUrl("PROPERTY") + "?key=REACT_APP_TERMS_AND_CONDITIONS_URL";
  if (sessionStorage.getItem("termsAndConditionsURL") === null) {
    fetch(_termsAndConditionsURL,requestOptions)
      .then(response => response.text())
      .then((response) => {
        if (!response.includes('Property does not exist')) {
          sessionStorage.setItem("termsAndConditionsURL", response);
          setTerms(response);
        }
      })
      .catch(err => console.log(err))
  }

  const [privacyPolicyURL, setPrivacyPolicy] = useState("#");
  const _privacyPolicyURL = getApiUrl("PROPERTY") + "?key=REACT_APP_PRIVACY_POLICY_URL";
  if (sessionStorage.getItem("privacyPolicyURL") === null) {
    fetch(_privacyPolicyURL,requestOptions)
      .then(response => response.text())
      .then((response) => {
        if (!response.includes('Property does not exist')) {
          sessionStorage.setItem("privacyPolicyURL", response);
          setPrivacyPolicy(response);
        }
      })
      .catch(err => console.log(err))
  }


  const [termsAccepted, setTermsAccepted] = useState(false)

  const StartPaymentClick = () => {
    if (termsAccepted) {
      history.push("/pages/index2")
    } else {
      alert('You must accept the Terms and Conditions and acknowledge the Privacy Policy.')
      return false;
    }
  }

  const [isLoaded, setIsLoaded] = useState(false);

  const getButtonTitle = () =>{
    if(!termsAccepted) return "Start payment";
    return isLoaded ? "Start payment" : "Please wait..."
  }

  document.addEventListener("transactionLoaded", e => {
    setIsLoaded(true)
  });

  return (
    <>
      <View style={styles.container}>
        <Header initLoad={true} />
        <Text style={styles.Text3}>
          This facility has gone paperless<br /> due to COVID-19.
        </Text>
        <Text> </Text>
        <Text>
          Next Steps:
        </Text>
        <Text> </Text>
        <Text>
          1. Complete an electronic payment below
        </Text>
        <Text>
          2. Get your electronic receipt instantly
        </Text>
        <Text> </Text>
        <Text style={styles.Text2}>
          <br />
          Warning: Do not write a check. You must use cashless checkout.
          <br /><br />
        </Text>
        <Text> </Text>

        <table><tr>
          <td><RadioButton
            color='#40b0ff'
            status={termsAccepted === true ? 'checked' : 'unchecked'}
            onPress={() => setTermsAccepted(!termsAccepted)}
          /></td>
          <td><Text>I accept the Terms and Conditions and acknowledge the Privacy Policy.</Text></td>
        </tr></table>

        <Text> </Text>


        <Button disabled={!termsAccepted || !isLoaded} id='btnStartPayment' title={ getButtonTitle()} onPress={() => StartPaymentClick()}></Button>


        <br /><br />
        <center>
          <a style={{ color: '#00539b' }} target={"_blank"} href={termsAndConditionsURL}>Terms & Conditions</a>
          <br />
          <a style={{ color: '#00539b' }} target={"_blank"} href={privacyPolicyURL}>Privacy Policy</a>
        </center>
      </View>
    </>
  );

};

export default index;
