import Constants from 'expo-constants';

const __socketProtocol = window.location.origin.substring(0, 5) === "https" ? "wss://" : "ws://";

// TODO FIGURE OUT A WAY TO PUT EXTERNAL API URL INSTEAD OF window.location.origin.

const __apexDataRootUrl = Constants.manifest.extra.APIBaseUrl + '/load';
const __fiscalPostCheckUrl = Constants.manifest.extra.APIBaseUrl + '/payments/checks/auth';
const __webSocketTest = Constants.manifest.extra.WSAPIBaseUrl;
const __worldPayEndpoint = Constants.manifest.extra.APIBaseUrl + '/payments/creditcard'
const __emailReceiptEndpoint = Constants.manifest.extra.APIBaseUrl + '/payments/receipt/email'
const __textReceiptEndpoint = Constants.manifest.extra.APIBaseUrl + '/payments/receipt/text'
const __fiscalPostFuelCardUrl = Constants.manifest.extra.APIBaseUrl + '/payments/fuelcard'
const __relayPostRelayCode = Constants.manifest.extra.APIBaseUrl + '/payments/relay'
const __carrierlist = Constants.manifest.extra.APIBaseUrl + '/carrier'
const __property = Constants.manifest.extra.APIBaseUrl + '/property'
const __jpmDropInUIUrl = Constants.manifest.extra.APIBaseUrl + '/payments/getJPMDropInUIUrl'

export const getApiUrl = (apiName) => {
    if (apiName === 'APEX_DATA') {
        return __apexDataRootUrl;
    } else if (apiName === 'FISCAL_POST_CHECK') {
        return __fiscalPostCheckUrl;
    } else if (apiName === 'WEBSOCKET_TEST') {
        return __webSocketTest;
    } else if (apiName === 'WORLD_PAY') {
        return __worldPayEndpoint;
    } else if (apiName === 'FISCAL_EMAIL_RECEIPT') {
        return __emailReceiptEndpoint;
    } else if (apiName === 'FISCAL_TEXT_RECEIPT') {
        return __textReceiptEndpoint;
    } else if (apiName === 'FISCAL_POST_FUEL_CARD') {
        return __fiscalPostFuelCardUrl;
    } else if (apiName === 'RELAY_POST_RELAY_CODE') {
        return __relayPostRelayCode;
    } else if (apiName === 'CARRIER_LIST') {
        return __carrierlist;
    } else if (apiName === 'PROPERTY') {
        return __property;
    }
    else if (apiName === 'JPM_CHECKOUT_URL') {
        return __jpmDropInUIUrl;
    }

    return '';
}

