import React, { useState, useEffect } from 'react'
import Header from '../shared/header'
import Footer from '../shared/footer'
import { Button, Image, StyleSheet, Text, View, TextInput } from 'react-native';
import { styles } from '../shared/styles'
import { getTransactionId } from '../shared/utilities/urlUtils'

const helpPage = ({ history }) => {

    const [helpCode, setHelpCode] = useState('Loading...');

    const getHelpTicketNumber = () => {
        let loadID = getTransactionId(window.location.href);
        //let parts = loadID.split("-")
        //setHelpCode(parts[0]);
        setHelpCode("" + loadID)
    }

    useEffect(() => {
        getHelpTicketNumber();
    }, []);

    return (
        <View style={styles.container}>
            <Header showAmount='true' showBack='true' history={history} />
            <h1>Need Assistance?</h1>
            <p>
                Talk to a representative to get your problem solved today.
            </p>
            <p>
                If there is an issue not related to payment, please contact the warehouse.
            </p>
            <p>
                Call Us<br />
                <strong>1-800-838-4549 Option 7</strong>
            </p>
            <p>
                Your Load ID:<br />
                <Text style={styles.bigBlack}><strong>{helpCode}</strong></Text>
            </p>
            <Footer />
        </View>
    );

}

export default helpPage;