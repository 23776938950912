import React, { Component, useState } from "react";
import { getApiUrl } from "../shared/apis";
import { getTransactionId } from "../shared/utilities/urlUtils";
import { withRouter } from "react-router-native";
import { TxStatus } from "../shared/utilities/transactionStatus";
const signalR = require("@microsoft/signalr");

class SocketTest extends Component {
  state = {
    message: "",
    display: false,
  };
  componentDidMount() {
    var connection = new signalR.HubConnectionBuilder()
      .withUrl(
        getApiUrl("WEBSOCKET_TEST") +
          "?txn_id=" +
          getTransactionId(window.location.href),
        {
          transport: signalR.HttpTransportType.WebSockets,
        }
      )
      .withAutomaticReconnect()
      .build();

    connection
      .start()
      .catch((err) =>
        delay(2000).then(() =>
          connection.start().catch((err) => console.error(err.toString()))
        )
      );
      
    connection.on("exit", (data) => {
      addMessage({ action: "close" });
    });


    connection.onclose((error) => {
      console.log("connection closed", error);
      retryConnection();
    });
    connection.on("message", (data) => {
      console.log("message received", data)
      const message = JSON.parse(data);
      addMessage(message);
    });

    const retryConnection = () => {
        setTimeout(() => {
            if (connection.state === signalR.HubConnectionState.Disconnected) {
                connection.start().then(() => {
                    console.log("Connection started successfully.");
                }).catch((err) => {
                    console.error(err.toString());
                    retryConnection();
                });
            }
        }, 2000);
    };


    const addMessage = (_message) => {
      this.setState((state) => ({
        message: JSON.stringify(_message),
      }));

      const { history } = this.props;

      if (_message.status) {
        if (_message.status === TxStatus.STATUS_AUTHORIZED) {
          history.push("/pages/success");
        }
      }

      if (_message.status) {
        if (
          _message.status === TxStatus.STATUS_CANCELED ||
          _message.status === TxStatus.STATUS_USER_CANCELED
        ) {
          history.goBack();
        }
      }

      if (_message.action) {
        if (_message.action === "close") {
          history.push("/pages/sessionReplaced");
        }
      }
    };
  }

  render() {
    return <div></div>;
  }
}

export default withRouter(SocketTest);
