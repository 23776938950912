import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-native';
import { StyleSheet, Text, View, Image } from 'react-native'
import { styles } from '../shared/styles'


const Footer = () => {

    return (
        <View styles={styles.container}>
            <View>
                <br />
                <hr style={{ width: "100%", height: 1 }} />
                <center>
                    <a style={{ color: '#00539b' }} target={"_blank"} href={sessionStorage.getItem("termsAndConditionsURL")}>Terms & Conditions</a>
                    <b> · </b>
                    <a style={{ color: '#00539b' }} target={"_blank"} href={sessionStorage.getItem("privacyPolicyURL")}>Privacy Policy</a>
                </center>
                {/* <hr style={{ width: "100%", height: 1 }} /> */}
            </View>
        </View>

    )
}

export default withRouter(Footer)