import React from 'react';
import { useState, useEffect } from 'react'

import { Button, Image, StyleSheet, Text, View } from 'react-native';
import Header from '../shared/header'
import Footer from '../shared/footer'
import { styles } from '../shared/styles'
import { getApiUrl } from '../shared/apis';
import { getTransactionId } from '../shared/utilities/urlUtils';

export default ({ history }) => {

  var tog = JSON.parse(sessionStorage.getItem("load")).paymentMethods

  return (

    <View style={styles.container}>

      <Header showAmount='true' showHelp='true' showBack='true' history={history} />
      <Text>Select Fleet Check Type:</Text>
      <Text> </Text>
      {tog.ComdataFleetChecks ? <Button id='btnComChek' title="Comchek" onPress={() => history.push("/pages/comChek")}></Button> : ""}
      <Text> </Text>
      {tog.WexFleetCards ? <Button id='btnEFS' title="EFS" onPress={() => history.push("/pages/eFS")}></Button> : ""}
      <Text> </Text>
      {tog.WexFleetCards ? <Button id='btnTChek' title="Tchek" onPress={() => history.push("/pages/tChek")}></Button> : ""}
      <Text> </Text>

      <Button id='btnCancel' title="Back" onPress={() => history.goBack()}></Button>
      <Footer />
    </View>
  );

}

/*


*/