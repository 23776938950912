import React from 'react';
import { StyleSheet, View } from 'react-native';
import { NativeRouter, Switch, Route } from 'react-router-native';

import Index from "./pages/index"
import FuelCard from "./pages/fuelCard"
import CreditCard from "./pages/creditCard"
import Relay from "./pages/relay"
import ComChek from "./pages/comChek"
import EFS from "./pages/eFS"
import FleetOne from "./pages/fleetOne"
import TChek from "./pages/tChek"
import FleetCheckPicker from "./pages/fleetCheckPicker"
import FuelCardPicker from "./pages/fuelCardPicker"
import Index2 from "./pages/index2"
import Index2_1 from "./pages/index2_1"
import Index3 from "./pages/index3"
import Index4 from "./pages/index4"
import Help from "./pages/help"
import Error from "./pages/error"
import Success from "./pages/success"
import SocketTest from "./shared/socketTest";
import { getTransactionId } from './shared/utilities/urlUtils';
import FleetCardNote from "./pages/fleetCardNote"
import SessionReplaced from "./pages/sessionReplaced"

export default function App() {

  return (
    <View>
      <NativeRouter >
        <View style={styles.container}>
          <Switch >
            <Route exact path="/" component={Index} />
            <Route exact path="/pages/fuelCard" component={FuelCard} />
            <Route exact path="/pages/creditCard" component={CreditCard} />
            <Route exact path="/pages/relay" component={Relay} />
            <Route exact path="/pages/fleetCheckPicker" component={FleetCheckPicker} />
            <Route exact path="/pages/fuelCardPicker" component={FuelCardPicker} />
            <Route exact path="/pages/comChek" component={ComChek} />
            <Route exact path="/pages/eFs" component={EFS} />
            <Route exact path="/pages/tChek" component={TChek} />
            <Route exact path="/pages/fleetOne" component={FleetOne} />
            <Route exact path="/pages/index2" component={Index2} />
            <Route exact path="/pages/index2_1" component={Index2_1} />
            <Route exact path="/pages/index3" component={Index3} />
            <Route exact path="/pages/index4" component={Index4} />
            <Route exact path="/pages/help" component={Help} />
            <Route exact path="/pages/error" component={Error} />
            <Route exact path="/pages/success" component={Success} />
            <Route exact path="/pages/fleetCardNote" component={FleetCardNote} />
            <Route exact path="/pages/sessionReplaced" component={SessionReplaced} />
          </Switch>
        </View>
        {getTransactionId(window.location.href) === 0 ? "" : <SocketTest />}
      </NativeRouter>
    </View >
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center"
  }
});

