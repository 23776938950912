import React from 'react';
import { TextInput, Button, Label, Image, StyleSheet, Text, View } from 'react-native';
import Header from '../shared/header'
import Footer from '../shared/footer'
import { styles } from '../shared/styles'


export default ({ history }) => (
  <View style={styles.container}>

    <Header />
    <Text>Express Code:</Text>
    <TextInput style={{ height: 40, borderColor: 'gray', borderWidth: 1 }} />
    <Text> </Text>
    <Text>Driver's License Number:</Text>
    <TextInput style={{ height: 40, borderColor: 'gray', borderWidth: 1 }} />
    <Text> </Text>
    <Text>Driver's License State:</Text>
    <TextInput style={{ height: 40, borderColor: 'gray', borderWidth: 1 }} />
    <Text> </Text>
    <Text>Account Number:</Text>
    <TextInput style={{ height: 40, borderColor: 'gray', borderWidth: 1 }} />
    <Text> </Text>
    <Text>Driver Number:</Text>
    <TextInput style={{ height: 40, borderColor: 'gray', borderWidth: 1 }} />
    <Text> </Text>
    <Text>Trailer Number:</Text>
    <TextInput style={{ height: 40, borderColor: 'gray', borderWidth: 1 }} />
    <Text> </Text>
    <Text>Unit Number:</Text>
    <TextInput style={{ height: 40, borderColor: 'gray', borderWidth: 1 }} />
    <Text> </Text>

    <Button type="submit" title="Pay"></Button>
    <Text> </Text>
    <Button title="Cancel" onPress={() => history.goBack()}></Button>
    <Footer />
  </View>
);
